<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
html,
body,
#app {
  width: 100%;
  height: 100%;
  background: #ffffff;
  position: relative;
  color: #333333;
  overflow: hidden;
  margin: 0;
}
</style>
