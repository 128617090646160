import Vue from "vue";
import Router from "vue-router";
// import store from "../store/index";

Vue.use(Router);
// 解决路由重复点击报异常问题
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "",
    component: () => import("../views/home.vue"),
    meta: {
      title: "",
    },
  },
];

const router = new Router({
  base: "/",
  routes,
});

export default router;
